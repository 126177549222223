<div class="jumbotron jumbotron-fluid bg-success text-white">
    <div class="container">
        <h1 class="display-4 font-italic" style="text-align: left;">RS Automation, Testimonios y relatos.</h1>
        <p class="lead my-3" style="text-align: left;">La importancia de satisfacer al clientes es conocer su experiencia con nosotros, por ello contamos con relatos y testimonios de cada uno de ellos.</p>
        
    </div>
</div>
<div class="album py-5 bg-light">
    <div class="container">

      <div class="row">
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">

            <div class="card-body">
                <h2>Nombre u empresa que da el testimonio</h2>
            
                <p class="card-text">Testimonio u relato</p>
            </div>
            
        </div>

    </div>
    <div class="col-md-4">
        <div class="card mb-4 shadow-sm">
          <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">

          <div class="card-body">
            <h2>Nombre u empresa que da el testimonio</h2>
            
            <p class="card-text">Testimonio u relato</p>
            
          </div>
</div>  