import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-manager',
  templateUrl: './report-manager.component.html',
  styleUrls: ['./report-manager.component.css']
})
export class ReportManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
