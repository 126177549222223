<div class="jumbotron jumbotron-fluid bg-success text-white">
    <div class="container">
        <h1 class="display-4 font-italic" style="text-align: right;">RS Automation, Casos de éxito.</h1>
        <p class="lead my-3" style="text-align: right;">proyectos profesionales de automatización cuyo fin es dar innovación y proporcionar un producto de calidad tecnológica.</p>
        
    </div>
</div>
<div class="container">
<div class="row featurette">
  <div class="col-md-7">
    <h2 class="featurette-heading"> RS LOGISTICS. <span class="text-muted">Aplicación Web de logística para control de accesos vehículares y personal.</span></h2>
    <p class="lead">Aplicación diseñada para el control de entradas y salidas de vehículos, proveedores y clientes a la planta enlazada con la(s) basculas de camiones. Control de accesos personal interno/externo.</p>
  </div>
  <div class="col-md-5">
    <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/img/image/rslogistic logo.png" width="95%" height="95%" alt="...">
  </div>
</div>

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7 order-md-2">
    <h2 class="featurette-heading">RS Report Viewer. <span class="text-muted">Sistema informático para el monitoreo y manejo de información de la producción.</span></h2>
    <p class="lead">Aplicación Web de modelo logístico
      , actualmente instalado en:
    Cal del Valle-grupo Calidra, Hgo. Mex, Cal de Apasco-grupo Calidra, Apasco Edo de Mex.</p>
  </div>
  <div class="col-md-5 order-md-1">
    <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/img/rslogistic/RS Report Viewer3 1800x710.png" width="95%" height="95%" alt="...">

  </div>
</div>

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7">
    <h2 class="featurette-heading">Access Control System.<span class="text-muted">Sistema de control de acceso para el cliente y los proveedores.</span></h2>
    <p class="lead">Software para el control de acceso a la planta. Clientes, proveedores, trabajadores. Nuestro sistema controla las básculas de camiones. Todo el proceso es automático, tiene conexión con el ERP
      .</p>
  </div>
  <div class="col-md-5">
    <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/access.png" width="95%" height="95%" alt="...">

  </div>
</div>

</div>
