<section class="jumbotron text-white bg-success">
    <div class="container">
        <h1 class="display-4 font-italic" style="text-align: right;">Contáctenos.</h1>
        <p class="lead my-3" style="text-align: right;">Comunícate con nosotros queremos atenderte, en RS Automation nuestra prioridad es darte el mejor servicio de calidad y profesionalismo.</p>
  
    </div>
</section><br>

     


    <div class="container">
        <div class="row">
            <div class="col-sm">
                <div class="card shadow-lg p-3 mb-5 bg white">
                    <div class="card-header text-center"> <img src="assets/imagenes/Rslogo.png" width="10%"  height="10%" alt=""> <h2 class="text-success">Your Partner in Automation and IT solution</h2> </div>
                    <div class="card-body">
                        <form (ngSubmit)="contactForm(form)" #form="ngForm" class="needs-validation" novalidate>
                            <h1 class="font-weight-bold mb-3 text-center">INFORMACIÓN DE CONTACTO</h1>
                     
                            <p class="text-muted mb-5 text-center">Queremos conocer más sobre tí.</p>
                            <div class="form-row mb-2">
                                <div class="form-group col-md-6">
                                    <label class="font-weight-bold" id="validacion">Nombre completo <span><i class="fa fa-user"></i></span></label>
                                    <input type="text" class="form-control"  placeholder="Nombre..." id="validacion"  name="nombre" required #name='ngModel' ngModel>
                                    <div class="text-danger"  *ngIf="name.invalid">Completa el campo requerido</div>
                                   
                                    
                                    
                                    
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="font-weight-bold">Asunto <span><i class="fa fa-user"></i></span></label>
                                    <input type="text" class="form-control" placeholder="Asunto..." required id="name"  name="asunto" #asunto="ngModel" ngModel>
                                    <div class="text-danger" *ngIf="asunto.invalid">Completa el campo requerido
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="font-weight-bold">Correo electrónico <span><i class="fa fa-at"></i></span></label>
                                <input type="email" class="form-control" placeholder="Ingresa tu correo electrónico..." required name="email" #correo='ngModel' ngModel pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}">
                                <div class="text-danger" *ngIf="correo.invalid">Completa el campo requerido</div>
                                <div class="text-success" *ngIf="correo.valid">Email aceptable</div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="font-weight-bold">Mensaje <span><i class="fa fa-envelope"></i></span></label>
                                <textarea type="text" class="form-control" required name="mensaje" #mensaje='ngModel' ngModel></textarea>
                                <div class="text-danger" *ngIf="mensaje.invalid"> Completa el campo requerido</div>
                            </div>
                        
                           
                            <div class="row w-100">
                                <button class="btn btn-outline-success btn-lg btn-block">  <span *ngIf="loading" class="spinner spinner-border spinner-border-sm"></span> Enviar info</button>
                                
                            </div>
                       
                        </form>
   
                    </div>
                    
                </div>
                <div class="col-sm">
                    <div class="card text-center shadow-lg p-3 mb-5 bg-white rounded">
                        <div class="card-header">
                          <img src="assets/imagenes/Rslogo.png" width="10%"  height="10%" alt="">
                          <h2 class="text-success">Your Partner in Automation and IT solution</h2>
                        </div>
                        <div class="card-body">
                          <h2 class="card-title">Ubicación</h2>
                          <p class="card-text">Estamos ubicados en: Calle de Sevilla 142, Gabriel Pastor 2da Secc, CP: 72425, Puebla, Pue.</p>
                        
                        </div>
                        <div class="map-responsive">
                        
                            <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.8899219
                            702625!2d-98.21437668562393!3d19.024571658531922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s
                            0x85cfc0b73d8f64cd%3A0x185ac8c47afcf8d!2sCalle%20de%20Sevilla%20142%2C%20Gabriel%20Pastor%202da%20Secc%2C%20
                            Puebla%2C%20Pue.!5e0!3m2!1ses!2smx!4v1619707906988!5m2!1ses!2smx" 
                            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                           
                    </div>
                
                      </div>
            </div>
        </div>
    </div>
         
</div>
    




