import { Component, OnInit,  } from '@angular/core';
import { MessageService } from './../services/message.service';
import swal from 'sweetalert';
import { FormControl, FormGroup, NgForm, Validators, FormBuilder } from '@angular/forms';
import { from } from 'rxjs';





@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  public loading!: boolean;
  constructor(public _MessageService: MessageService) {
  }
    
   contactForm(form: NgForm) {

   this.loading = true;
     
   

    if (form.valid) {
      this._MessageService.sendMessage(form.value).subscribe(() => {
        swal("Formulario de contacto", "Mensaje enviado correctamente",'success');
         form.reset();
         this.loading = false;
         
        });
    }else if(form.invalid){
      swal("Formulario de contacto", "Datos incorrectos, se requiere el llenado completo y correcto del formulario",'error');
      form.reset();
      this.loading=false;
      return;
      
    }

   
  
   }
 

  ngOnInit(): void {
    
  }
 
}
