
  <div id="carouselReportManager" class="carousel slide carousel-fade" data-ride="carousel">
       
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/img/rslogistic/RS Report Viewer 1800x710.png" class="d-block w-100" alt="...">
        <div class="container">
          <div class="carousel-caption text-left p-3 mb-2 bg-transparent">
           
            
            
          </div>
        </div>
      </div>
      
      <div class="carousel-item">
        <img src="assets/img/rslogistic/RS Report Viewer2 1800x710.png" class="d-block w-100" alt="...">
        <div class="container">
        <div class="carousel-caption text-center p-3 mb-2 bg-transparent">
          
          
          
        </div>
        </div>
      </div>
      <div class="carousel-item">
        <img src="assets/img/rslogistic/RS Report Viewer3 1800x710.png" class="d-block w-100" alt="...">
        <div class="container">
        <div class="carousel-caption text-center p-3 mb-2 bg-transparent">
         
          
        
        </div>
        </div>
      </div>
      
    </div>
    <a class="carousel-control-prev" href="#carouselReportManager" role="button" data-slide="prev">
      
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselReportManager" role="button" data-slide="next">
      
      <span class="sr-only">Next</span>
    </a>
  </div>
  <div>
  
    <div>

    </div>
      <div class="container">
        <div class="row featurette">
          <div class="col-md-8">
            <h2 class="featurette-heading">RS Report Manager:  <span class="font-italic text-success">Sistema informático para el monitoreo y manejo de información de la producción.</span></h2><hr>
            <p class="lead text-justify">Aplicación Web de modelo logístico
              , actualmente instalado en:
            Cal del Valle-grupo Calidra, Hgo. Mex, Cal de Apasco-grupo Calidra, Apasco Edo de Mex.
            </p>
          </div>
          <div class="col-md-4">
            <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/Rslogo.png" width="95%" height="95%" alt="...">
    
          </div>
        </div><br>
     
      </div>
      
   
    