import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { NavbarComponent } from './navbar/navbar.component';
import { CasosdexitoComponent } from './casosdexito/casosdexito.component';
import { BlogComponent } from './blog/blog.component';
import { TestimonioComponent } from './testimonio/testimonio.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ContactoComponent } from './contacto/contacto.component';
import { HomeComponent } from './home/home.component';
import { PiePaginaComponent } from './pie-pagina/pie-pagina.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { ReportManagerComponent } from './report-manager/report-manager.component';
import { ControlLogisticoComponent } from './control-logistico/control-logistico.component';
import { MessageService } from './services/message.service';
import { ArticuloComponent } from './blog/articulo/articulo.component';
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component';



@NgModule({
  declarations: [
    AppComponent,
    ServiciosComponent,
    NavbarComponent,
    CasosdexitoComponent,
    BlogComponent,
    TestimonioComponent,
    ClientesComponent,
    ContactoComponent,
    HomeComponent,
    PiePaginaComponent,
    NosotrosComponent,
    ReportManagerComponent,
    ControlLogisticoComponent,
    ArticuloComponent,
    AvisoPrivacidadComponent,
   

   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
