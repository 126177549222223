<div class="jumbotron jumbotron-fluid bg-success text-white">
    <div class="container">
        <h1 class="display-4 font-italic" style="text-align: left;">RS Automation, Servicios.</h1>
        <p class="lead my-3" style="text-align: left;">Nuestro compromiso es dar un servicio de excelencia y calidad, dando uso de conocimientos tecnológicos e innovación.</p>
        
    </div>
</div>
<div class="container">
<div class="row row-cols-1 row-cols-md-2">
    <div class="col mb-4">
      <div class="card shadow-sm" style="height: 100%;">
        <img src="assets/imagenes/eplan36.jpg"  class="card-img-top"  alt="...">
        <div class="card-body">
          <h5 class="card-title">Hardware Engineering</h5>
          <p class="card-text">Desarrollamos la ingeniería de hardware en Eplan o Autocad Electrical.</p>
          <!--button type="button" class="btn btn-sm btn-outline-success">Ver</button--->
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card shadow-sm" >
        <img src="assets/imagenes/tablero.jpg"    class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">Control Cabinets Construction</h5>
          <p class="card-text">En nuestro taller fabricamos gabinetes de control y distribución para diferentes tipos de industrias.
          </p>
          <!--button type="button" class="btn btn-sm btn-outline-success">Ver</button-->
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card shadow-sm" style="height: 100%;">
        <img src="assets/imagenes/programadores.jpg" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">PLC + HMI Programming</h5>
          <p class="card-text">Contamos con personal altamente capacitado en programación en el área de PLC + HMI.</p>
          <!--button type="button" class="btn btn-sm btn-outline-success">Ver</button-->
        </div>
      </div>
    </div>
    <div class="col mb-4">
      <div class="card shadow-sm">
        <img src="assets/imagenes/it3.jpg" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">IT Custom software</h5>
          <p class="card-text">Ofrecemos soluciones de IT en diseño y desarrollo de apps integrales a los procesos laborales de cada industria.</p>
          <div class="d-flex justify-content-between align-items-center">
            
              <!--button type="button" class="btn btn-sm btn-outline-success">Ver</button-->
          
          
        </div>
      </div>
    </div>
  </div>
  <div class="col mb-4">
    <div class="card shadow-sm" style="height: 100%;">
      <img src="assets/imagenes/vision2.jpg" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">Vision System Safety Solutions</h5>
        <p class="card-text">Proporcionamos soluciones de seguridad para todo tipo de industrias.</p>
        <div class="d-flex justify-content-between align-items-center">
          
            <!--button type="button" class="btn btn-sm btn-outline-success">Ver</button-->
        
        
      </div>
    </div>
  </div>
</div>
<div class="col mb-4">
  <div class="card shadow-sm">
    <img src="assets/imagenes/kinetix.jpg" class="card-img-top" alt="...">
    <div class="card-body">
      <h5 class="card-title">Kinetix Motion Systems</h5>
      <p class="card-text">Nuestros ingenieros apoyan proyectos de movimiento en la industria alimentacia y de bebidas, así como la automotriz entre otras.</p>
      <div class="d-flex justify-content-between align-items-center">
        
          <!--button type="button" class="btn btn-sm btn-outline-success">Ver</button-->
      
      
    </div>
  </div>
</div>
</div>
  
</div>



  