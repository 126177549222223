import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common'
import { RouterModule, Routes } from '@angular/router';
import{ServiciosComponent} from "./servicios/servicios.component";
import{CasosdexitoComponent} from "./casosdexito/casosdexito.component";
import { BlogComponent } from './blog/blog.component';
import { TestimonioComponent } from './testimonio/testimonio.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ContactoComponent } from './contacto/contacto.component';
import { HomeComponent } from './home/home.component';
import {NosotrosComponent} from './nosotros/nosotros.component';
import { ReportManagerComponent } from './report-manager/report-manager.component';
import { ControlLogisticoComponent } from './control-logistico/control-logistico.component';
import { ArticuloComponent } from './blog/articulo/articulo.component';
import { AvisoPrivacidadComponent } from './aviso-privacidad/aviso-privacidad.component';

const routes: Routes = [
 
  {path:'servicios', 
  component: ServiciosComponent}, 
  {path:'casosdexito', 
  component: CasosdexitoComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'articulo', component: ArticuloComponent},
  {path:'testimonio', component: TestimonioComponent},
  {path: 'clientes', component: ClientesComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: 'home', component: HomeComponent},
  {path: 'nosotros', component: NosotrosComponent},
  {path: 'reportManager', component: ReportManagerComponent},
  {path: 'controlLogistico', component: ControlLogisticoComponent},
  {path: 'avisoPrivacidad', component: AvisoPrivacidadComponent},
  {path: '', component: HomeComponent},
  {path: '**', component: HomeComponent}, 


];

@NgModule({
  imports: [CommonModule, 
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

