<footer class="text-center footer-style font-small page-footer container">
	<div class="row">
    
		<div class="container mx-auto copy">
      <br>
      <h4 style="text-align: center;">Síguenos</h4>
      
      <ul class="social-network social-circle">
      
       <li><a href="https://www.linkedin.com/company/rs-automation-mexico/" target="_blank" class="icoLinkedin" title="Linkedin/Rs Automation México"><i class="fa fa-linkedin"></i></a></li>
       <li><a href="#" class="icoYoutube" title="Youtube/RsAutomation"><i class="fa fa-youtube"></i></a></li>
     
       
      </ul>	
     
			<p class="text-center">Calle de Sevilla #142, col. Gabriel Pastor 2da Seccion CP: 72425 &#x2022; Puebla, Pue. México &#x2022; <span class="fa fa-phone"></span> +52 1 222 295 9437 &#x2022; ROCHA SANCHEZ AUTOMATIZACION, S.A. de C.V. <a routerLink="/avisoPrivacidad">Aviso de privacidad</a> &copy; Copyright {{anio}}</p>
      
		</div>
	</div>
</footer>

 

