
<div id="carouselServicios" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselServicios" data-slide-to="0" class="active"></li>
    <li data-target="#carouselServicios" data-slide-to="1"></li>
    <li data-target="#carouselServicios" data-slide-to="2"></li>
    <li data-target="#carouselServicios" data-slide-to="3"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/img/cemento.png" class="d-block w-100">
      <div class="container">
        <div class="carousel-caption text-left p-5 mb-2 bg-transparent " >
          <h1>Cemento y Cal</h1>
          <p class="text-light ">Conoce más sobre nuestro servicio en el sector industrial.</p>
          <p><a class="btn btn-outline-light" style="text-align: center;" routerLink='/servicios'>Ver más</a></p>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/img/mineria.png" class="d-block w-100" width="100" height="" alt="...">
      <div class="container">
      <div class="carousel-caption text-center p-3 mb-2 bg-transparent">
        <h2>Minería</h2>
        <p class="text-light ">Damos solución a todas las necesidades de automatización y control de procesos industriales.</p>
        <p><a class="btn btn-outline-light" routerLink='/servicios'>Ver más</a></p>
      </div>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/img/alimentos2.png" class="d-block w-100" alt="...">
      <div class="container">
      <div class="carousel-caption text-center p-3 mb-2 bg-transparent">
        <h2>Alimentos y Bebidas</h2>
        <p class="text-light">Contamos con amplia experiencia en proyectos de seguridad en maquinaria.</p>
        <p><a class="btn btn-outline-light" routerLink='/servicios'>Ver más</a></p>
      </div>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/img/automotriz.png" class="d-block w-100" alt="...">
      <div class="container">
        <div class="carousel-caption text-right p-3 mb-2 bg-transparent">
          <h2>Automotriz</h2>
          <p class="text-light">ofrecemos soluciones de automatización integral a todo tipo de maquinas y equipos.</p>
          <p><a class="btn btn-outline-light" routerLink='/servicios'>Ver más</a></p>
        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselServicios" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselServicios" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
<!---------------------------------------------------------------------------------->

<div class="container marketing">

  <!-- Three columns of text below the carousel -->
  <div class="row">
    <div class="col-lg-4">
      <img src="assets/img/iconos/misión 500.png" class="align-self-star mr-3 shadow-lg p-3 mb-5 bg-transparent" width="90%" alt="..." style="border-radius: 100%;">
      <h1>Misión</h1><br>
      <p style="text-align: justify;">Brindar productos y servicios de automatización para el control de procesos industriales con la tecnología adecuada, enfocando nuestra experiencia y la de nuestro personal a nivel mundial para proveer soluciones tecnológicas, que generen satisfacción en nuestros clientes y colaboradores.</p> <br><br>      
    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4">
      <img src="assets/img/iconos/visión 500.png" class="align-self-star mr-3 shadow-lg p-3 mb-5 bg-transparent" width="90%" alt="..." style="border-radius: 100%;">

      <h1>Visión</h1><br>
      <p style="text-align: justify;">Ser la empresa líder en el mercado mundial de automatización de procesos industriales, comprometidos con la calidad de nuestros servicios, innovación tecnológica y desarrollo de nuestro personal, generando valor a nuestros clientes, en cada uno de los proyectos. </p> <br><br>    
    </div><!-- /.col-lg-4 -->
    <div class="col-lg-4">
      <img src="assets/img/iconos/política de calidad 500.png" class="align-self-star mr-3 shadow-lg p-3 mb-5  bg-transparent" width="90%" alt="..." style="border-radius: 100%;">

  <h1>Nuestra política</h1><br>
  <p  class="text-justify">Lograr la satisfacción permanente de las necesidades y expectativas de los clientes, manteniendo un excelente nivel de calidad de servicios; en procesos de automatización, sistemas de control y programación industrial.</p>
    </div><!-- /.col-lg-4 -->
  </div><!-- /.row -->
 



