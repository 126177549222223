<div class="jumbotron jumbotron-fluid bg-success text-white">
    <div class="container">
        <h1 class="display-4 font-italic" style="text-align: left;">RS Automation, Nuestros clientes.</h1>
        <p class="lead my-3" style="text-align: left;">Contamos con un gran grupo de clientes internacionales y de varios sectores de la república mexicana.</p>
        
    </div>
</div>
<div class="container">
<div class="row featurette">
    <div class="col-md-8">
      <h2 class="featurette-heading">Mondelez International, Inc. </h2><hr>
      <p class="lead" style="text-align: justify;">Mondelēz International, Inc. es un conglomerado multinacional mexico-estadounidense dedicado a las industrias de la confitería, alimentación y bebidas, con sedes en Deerfield, Illinois, Chicago y la Ciudad de México, que emplea a unas 100.000 personas en todo el mundo. Está integrada por las marcas globales de alimentos de la antigua Kraft Foods, a la que sucedió en 2012.
      </p>
    </div>
    <div class="col-md-4">
      <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/Mondelez.png" width="95%" height="95%" alt="...">

    </div>
  </div><hr>
  <div class="row featurette">
    <div class="col-md-8">
      <h2 class="featurette-heading">Cooperativa La Cruz Azul S.C.L </h2><hr>
      <p class="lead" style="text-align: justify;">Es una empresa mexicana de la industria de la construcción. Cuenta con cuatro plantas, dos son constituidas como Cooperativas y las otras dos constituidas como Sociedades Anónimas de Capital Variable, denominada CYCNA.
      </p>
    </div>
    <div class="col-md-4">
      <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/cruz azul.png" width="95%" height="95%" alt="...">

    </div>
  </div><hr>
  <div class="row featurette">
    <div class="col-md-8">
      <h2 class="featurette-heading">Grupo Calidra </h2><hr>
      <p class="lead" style="text-align: justify;">Es un fabricante mexicano de cal y carbonato de calcio para los sectores de la construcción, estabilización, minería, siderurgia, química y medio ambiente, y agricultura y alimentos. Sus productos incluyen cal hidratada, hidróxido de calcio, óxido de calcio, y nutriente concentrado de calcio y magnesio.
      </p>
    </div>
    <div class="col-md-4">
      <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/calidra.png" width="95%" height="95%" alt="...">

    </div>
  </div><hr>
  <div class="row featurette">
    <div class="col-md-8">
      <h2 class="featurette-heading">ThyssenKrupp AG </h2><hr>
      <p class="lead" style="text-align: justify;">Es una empresa de la industria siderúrgica alemana y es la más importante en el país dedicada a la fundición y forja del acero.
      </p>
    </div>
    <div class="col-md-4">
      <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/thyssen.png" width="95%" height="95%" alt="...">

    </div>
  </div><hr>
  <div class="row featurette">
    <div class="col-md-8">
      <h2 class="featurette-heading">Magneti Marelli </h2><hr>
      <p class="lead" style="text-align: justify;">Es una empresa italiana filial de Fiat S.p.A. de desarrollo y fabricación de sistemas, módulos y componentes para la industria de la automoción, con 34.269 empleados y una facturación de 5.402 millones de euros en 2010.
      </p>
    </div>
    <div class="col-md-4">
      <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/magneti.jfif" width="95%" height="95%" alt="...">

    </div>
  </div><hr>
  <div class="row featurette">
    <div class="col-md-8">
      <h2 class="featurette-heading">Loesche GmbH </h2><hr>
      <p class="lead" style="text-align: justify;">Es una empresa de ingeniería dirigida por sus propietarios, fundada en Berlín en 1906 y con sede actual en Düsseldorf (Alemania), que diseña, fabrica y presta servicio a molinos de rodillos verticales para la molienda de carbón, materias primas de cemento, escoria granulada, minerales industriales y menas. En la actualidad, más de 400 personas trabajan para Loesche en Alemania y unas 850 en todo el mundo.
      </p>
    </div>
    <hr>
    <div class="col-md-4">
      <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/loesche.jfif" width="95%" height="95%" alt="...">

    </div>
</div>
<hr>
<div class="jumbotron bg-success text-white jumbotron-fluid">
  <div class="container">
    <h1 class="display-4">Entre otros más</h1>
    <p class="lead">Tenemos un amplio catalogo de clientes muy importantes del ambito industrial.</p>
  </div>
</div>
<div class="imagenes">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/apasco.png"  alt="...">
        
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/bachoco.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/volkswagen.jpg"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/cementosFortaleza.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/toyota.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/tesla.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/chrysler.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/kuka.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/fives.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/rivian.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/it8.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/hella.png"  alt="...">
      </div>
      <div class="col-md-3">
        <img class="shadow-lg p-3 mb-5 bg-white rounded imagenes" src="assets/imagenes/magna.png"  alt="...">
      </div>
    </div>
   
  </div>
</div>


  

