<div id="carouselControlLogistico" class="carousel slide carousel-fade" data-ride="carousel">
       
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/img/rslogistic/RsLogistic 1800x710.png" class="d-block w-100" alt="...">
      <div class="container">
        <div class="carousel-caption text-left p-3 mb-2 bg-transparent">
          
          
          
        </div>
      </div>
    </div>
    
    <div class="carousel-item">
      <img src="assets/img/rslogistic/RsLogistic2 1800x710.png" class="d-block w-100" alt="...">
      <div class="container">
      <div class="carousel-caption text-center p-3 mb-2 bg-transparent">
       
        
        
      </div>
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/img/rslogistic/RsLogistic3 1800x710.png" class="d-block w-100" alt="...">
      <div class="container">
      <div class="carousel-caption text-center p-3 mb-2 bg-transparent">
        
        
      
      </div>
      </div>
    </div>
    
  </div>
  <a class="carousel-control-prev" href="#carouselControlLogistico" role="button" data-slide="prev">
    
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselControlLogistico" role="button" data-slide="next">
    
    <span class="sr-only">Next</span>
  </a>
</div>


 <div class="container">
    <div class="row featurette">
      <div class="col-md-8">
        <h2 class="featurette-heading">RS Control Logistico: <span class="font-italic text-success">Sistema de logística para control de accesos vehículares y personal.</span></h2><hr>
        <p class="lead" style="text-align: justify;">Aplicación diseñada para el control de entradas y salidas de vehículos, proveedores y clientes a la planta enlazada con la(s) basculas de camiones.
        Control de accesos personal interno/externo.
        </p>
      </div>
      <div class="col-md-4">
        <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/img/image/rslogistic logo.png" width="95%" height="95%" alt="...">
        
      </div>
    </div><br>
    
  </div>
