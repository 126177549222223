import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Users} from './users'

import { map } from 'rxjs/operators';
;
//import {Observable} from 'rxjs';


@Injectable()
export class MessageService {
constructor(private _https: HttpClient) { }
create(users: Users){
    return this._https.post('https://rs-mx-api.herokuapp.com/formulario', users)
   
}
    


sendMessage(body: any) {
 return this._https.post('https://rs-mx-api.herokuapp.com/formulario', body);
 }
}