<!---Estructura realizada faltan datos de todos los miembros de la empresa-->

<section class="jumbotron text-center bg-success text-white">
    
    <div class="container">
      <h1 class="display-4 font-italic" style="text-align: right;">RS Automation, Nosotros.</h1>
      <p class="lead my-3" style="text-align: justify;">Somos una empresa profesional, altamente capacitada para la automatización de procesos industriales con un toque de innovación tecnológica.</p>

    </div>
  </section>
  <div class="album py-5">
    <div class="container">

      <div class="row">
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">

            <div class="card-body">
              <h2>Ricardo Rocha Sánchez</h2>
              <h2>CEO</h2>
              
              <!-- <p class="card-text">rrocha@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
                </div>
                <div>
                  <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">

            <div class="card-body">
              <h2>Roque Jimenez Chappotin</h2>
              <h2>Gerente General</h2>
              <!-- <p class="card-text">rjimenez@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
                </div>
                <div>
                  <img src="assets/imagenes/banderaCuba1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Yaima Riverol Pérez</h2>
                <h2>Gerente Comercial/I+D </h2>
              <p class="card-text" style="text-align: justify;">yriverol@rs-automation.com.mx</p>
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaCuba1.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div--->
       
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Nallely Antonio Carreño</h2>
                <h2>Gerente administrativo</h2>
              <!-- <p class="card-text" style="text-align: justify;">ncarreno@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm" style="height: 96.5%;">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Peter Leven Ambriz</h2>
                <h2>Gerente de Ventas</h2>
              <!-- <p class="card-text" style="text-align: justify;">pleven@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center" style="height: 75%">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <!--div class="col-md-4">
          <div class="card mb-4 shadow-sm" >
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Enrique Campos Sarmiento</h2>
                <h2>Gerente de proyectos</h2>
              <p class="card-text" style="text-align: justify;">ecampos@rs-automation.com.mx</p>
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div--->
        <div class="col-md-4" >
          <div class="card mb-4 shadow-sm" style="height: 96.5%;">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Dasiel Alba Gonzalez</h2>
                <h2>Gerente de Proyectos</h2>
              <!-- <p class="card-text" style="text-align: justify;">dalba@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center" style="height: 60%">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaCuba1.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm" style="height: 96.5%;">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Sandra López Rojas</h2>
                <h2>Compras</h2>
              <!-- <p class="card-text" style="text-align: justify;">rscompras@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center" style="height: 60%">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Arath Hernández Rocha</h2>
                <h2>IT</h2>
              <!-- <p class="card-text" style="text-align: justify;">ahernandez@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm" style="height: 96.5%">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Jesús Cortés Alpizar</h2>
                <h2>Ingeniero jr de proyectos</h2>
              <!-- <p class="card-text" style="text-align: justify;">jcortes@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center" style="height: 50%">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Victor Hernández Dominguez</h2>
                <h2>Ingeniero jr de proyectos</h2>
              <!-- <p class="card-text" style="text-align: justify;">vhernandez@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Rubén Palafox Ramos</h2>
                <h2>Ingeniero jr de proyectos</h2>
              <!-- <p class="card-text" style="text-align: justify;">rpalafox@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Priscilla Mar Hernández</h2>
                <h2>Ingeniero jr de proyectos</h2>
              <!-- <p class="card-text" style="text-align: justify;">pmar@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm" style="height: 96%;">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Eduardo Huerta Salazar</h2>
                <h2>Técnico Eléctrico</h2>
              <!-- <p class="card-text" style="text-align: justify;">Contacto RS administrativo</p> -->
              <div class="d-flex justify-content-between align-items-center" style="height: 55%;">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm" style="height: 96%;">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Manuel García Vila</h2>
                <h2>Documentación e ingeniería</h2>
              <!-- <p class="card-text" style="text-align: justify;">mgarcia@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center" style="height: 55%;">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaCuba1.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm" style="height: 96.5%;">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Alain Luna Pérez</h2>
                <h2>Jefe de taller</h2>
              <!-- <p class="card-text" style="text-align: justify;">Contacto RS Administrativo.</p> -->
              <div class="d-flex justify-content-between align-items-center" style="height: 60%">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;"  height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaCuba1.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Alitza Quintero Marmol Roman</h2>
                <h2>Comercial</h2>
              <!-- <p class="card-text" style="text-align: justify;">rsventas@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..." style="top: -17%;"  width="30%" height="20%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-4 shadow-sm">
            <img src="assets/imagenes/Rslogo.png" class="d-block w-100" alt="...">
            <div class="card-body">
                <h2>Teresita Pérez Pérez</h2>
                <h2>Técnico en seguridad industrial</h2>
              <!-- <p class="card-text" style="text-align: justify;">tperez@rs-automation.com.mx</p> -->
              <div class="d-flex justify-content-between align-items-center">
               <div>
                    <img src="assets/imagenes/Rslogo1.png" class="d-block w-100" alt="..."  width="30%" style="top: -17%;" height="10%">
               
                </div>
                <div>
                    <img src="assets/imagenes/banderaMexico.png" class="d-block w-100" alt="..." width="30%"  height="10%">
                </div>
            
              </div>
            </div>
          </div>
        </div>

        