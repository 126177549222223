<!----Hacer un componente para cada información de los articulos---->
<!---Al momento hay 2 secciones de articulos actualizar dependiendo la cantidad de informacion necesaria---->
  <div class="jumbotron p-4 p-md-5 text-white rounded bg-success">
    <div class="container">
      <h1 class="display-4 font-italic">RS Automation, blog informativo.</h1>
      <p class="lead my-3">La finalidad del blog informativo es otorgar conocimientos sobre los conceptos más conocidos en los proyectos profesionales realizados en RS Automation.</p>
      
    </div>
  </div>
  <div class="container">
  <div class="row mb-2">
    <div class="col-md-6">
      <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
        <div class="col p-4 d-flex flex-column position-static">
          <strong class="d-inline-block mb-2 text-success">Engineering</strong>
          <h3 class="mb-0">Electronica</h3>
          <div class="mb-1 text-muted">Nov 11</div>
          <p class="mb-auto">La electrónica es ciertamente un sector particular de ingeniería...</p>
          <a  routerLink="/articulo" class="strech link text-success">Seguir leyendo</a>
        </div>
        <div class="col-auto d-none d-lg-block">
            <img src="assets/imagenes/automatizacion2.jpg" alt="..."  width="100%" height="100%">

        </div>
      </div>
    </div>
    <div class="col-md-6">
        <div class="row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
          <div class="col p-4 d-flex flex-column position-static">
            <strong class="d-inline-block mb-2 text-success">structure</strong>
            <h3 class="mb-0">Automatización</h3>
            <div class="mb-1 text-muted">Abr 18</div>
            <p class="mb-auto">La automatización es un sistema donde se trasfieren tareas de producción...</p>
            <a routerLink="/articulo" class="stretched-link text-success">Seguir leyendo</a>
          </div>
          <div class="col-auto d-none d-lg-block">
              <img src="assets/imagenes/automatizacion.jpg" alt="..."  width="100%" height="100%">
  
          </div>
        </div>
      </div>
    </div><br>
    <div class="row">
      <div class="col-md-8 blog-main">
        <h3 class="pb-4 mb-4 font-italic border-bottom text-success">
         RS Automation, Your Partner in Automation and IT solution
        </h3>
  
        <div class="blog-post">
          <h2 class="blog-post-title">Origen de la automatización</h2>
          <p class="blog-post-meta font-italic">Automatización Industrial (automatización; del griego antiguo auto: guiado por uno mismo) es el uso de sistemas o elementos computarizados para controlar maquinarias y/o procesos industriales sustituyendo a operadores humanos.
          </p>
  
          <p class="text-justify">El alcance va más allá que la simple mecanización de los procesos ya que ésta provee a operadores humanos mecanismos para asistirlos en los esfuerzos físicos del trabajo, la automatización reduce ampliamente la necesidad sensorial y mental del humano. La automatización como una disciplina de la ingeniería es más amplia que un mero sistema de control, abarca la instrumentación industrial, que incluye los sensores y transmisores de campo, los sistemas de control y supervisión, los sistema de transmisión y recolección de datos y las aplicaciones de software en tiempo real para supervisar y controlar las operaciones de plantas o procesos industriales.</p>
          <hr>
          <p class="text-justify">Las primeras maquinas simples sustituían una forma de esfuerzo en otra forma que fueran manejadas por el ser humano, tal como levantar un peso pesado con un <a href="https://sites.google.com/site/mecanismoscircuitos/mecanismos/mecanismos-de-transmision-del-movimiento/sistemas-de-poleas" target="_blank">sistema de poleas</a> o con una palanca. Posteriormente las maquinas fueron capaces de sustituir formas naturales de <a href="https://www.factorenergia.com/es/blog/noticias/energias-renovables-caracteristicas-tipos-nuevos-retos/" target="_blank">energía renovable</a>, tale como el viento, mareas, o un flujo de agua por energía humana.</p>
          <blockquote>
            <p>La parte más visible de la automatización actual puede ser la <strong>robótica industrial</strong>. Algunas ventajas son la repetitividad, control de calidad más estrecho , mayor eficiencia, integración con sistemas empresariales, incremento de productividad y reducción de trabajo.</p>
          </blockquote>
          <p class="text-justify">Algunas desventajas son: <em>requerimientos de una gran capital, decremento severo en la flexibilidad, y un incremento en la dependencia del mantenimiento y reparación.</em> Por ejemplo, Japón ha tenido necesidad de retirar muchos de sus robots industriales cuando encontraron que eran incapaces de adaptarse 
            a los cambios dramáticos de los requerimientos de producción y no eran capaces de justificar sus altos costos iniciales.</p>
            <p class="text-justify">Para mediados del siglo XX, la automatización había existido por muchos años en una escala pequeña, utilizando mecanismos simples para automatizar tareas sencillas de manufactura. Sin embargo el concepto solamente llego a ser realmente práctico con la adición y evolución de las computadoras digitales, cuya flexibilidad permitió manejar cualquier clase de tarea. Las computadoras digitales con la combinación requerida de velocidad, poder de cálculo, precio y tamaño empezaron a aparecer en la década de 1960. Antes de ese tiempo, las computadoras industriales eran exclusivamente computadoras analógicas y computadoras híbridas. Desde entonces las computadoras digitales tomaron el control de la mayoría de las tareas simples, repetitivas, tareas especializadas, con algunas excepciones notables en la producción e inspección de alimentos. Como un famoso dicho anónimo dice; "Para muchas y muy cambiantes tareas, es difícil remplazar al ser humano, quienes son fácilmente vueltos a entrenar dentro de un amplio rango de tareas, más aún, son producidos a bajo costo por personal sin entrenamiento".</p>
         
        </div><!-- /.blog-post -->
  
       
  
   
      </div><!-- /.blog-main -->
  
      <aside class="col-md-4 blog-sidebar">
        <div class="p-3 mb-7 bg-light rounded">
          <h4 class="font-italic">Momento de saber...</h4>
          <p class="mb-0 font-italicn" style="text-align: justify;">La automatización le ha permitido a las personas tener control total de los diversos objetos de uso cotidiano en cada uno de sus entornos. El hogar, el trabajo, los medios de transporte, los servicios, entre otros, son los ámbitos en los que se han generado soluciones inteligentes y creativas.</p>
        </div>
        <div class="p-5">
      
          <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/Rslogo.png" width="100%" height="100%" alt="...">
        </div>
      </aside>
     
      

     
      
  </div>
 
  </div>
  