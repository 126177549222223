    
    
    
    <nav class="navbar navbar-expand-md navbar-dark  bg-dark">
      <a class="text-success navbar-brand" href="/home">
        <img  src="/assets/imagenes/Rslogo.png" width="75px"  height="65px" class="d-inline-block align-top" >
      </a>
    
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="collapse navbar-collapse" id="navbarSupportedContent" aria-expanded="true">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link text-success" href='/home'>Inicio</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="/" id="dropdown03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Productos</a>
            <div class="dropdown-menu" aria-labelledby="dropdown03">
              <a class="dropdown-item text-success" href="/reportManager" >RS Report Manager</a>
              <a class="dropdown-item text-success" href="/controlLogistico">RS Control Logistico</a>
             
            </div>
          <li class="nav-item">
            <a class="nav-link" href='/nosotros' >Nosotros</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/servicios">Servicios</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/casosdexito">Casos de éxito</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/blog">Blog</a>
          </li>
          <!--li class="nav_item">
            <a class="nav-link" routerLink="/testimonio">Testimonio</a>
          </li-->
          <li class="nav-item">
            <a class="nav-link" href="/clientes">Clientes</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" href="/contacto">Contacto</a>
          </li>
        </ul>
      </div>
    </nav>
   
    

