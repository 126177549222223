<div class="jumbotron jumbotron-fluid bg-success text-white">
    <div class="container">
        <h1 class="display-4 font-italic" style="text-align: right;">RS Automation, Aviso de Privacidad.</h1>
        <p class="lead my-3" style="text-align: right;">Es importante para nosotros que conozcas perfectamente nuestro aviso de privacidad.</p>
    </div>
</div>
<div class="container">
   
      <div class="row">
        <div class="col-md-8 blog-main">
          <h3 class="pb-4 mb-4 font-italic border-bottom text-success">
           RS Automation, Your Partner in Automation and Ot solution
          </h3>
    
          <div class="blog-post">
            <h2 class="blog-post-title">CONOCE TODO SOBRE NUESTRO AVISO DE PRIVACIDAD</h2>
            
    
            <p class="text-justify">La empresa ROCHA SANCHEZ AUTOMATIZACION S.A. DE C.V. en Calle de Sevilla 142, Gabriel Pastor 2da Sección , CP 72425 Puebla, Pue, confome a lo establecido en la Ley Federal de Protección de Datos en posesión de particulares, pone a disposición de nuestros clientes, proveedores, empleados, nuestro Aviso de Privacidad.</p>
            
            
            <blockquote>
              <p>A nuestros clientes les solicitamos los siguientes datos personales: </p>
              <ul>
                  
                <li class="text-justify">Nombre, teléfono, correo electrónico y domicilio para facilitar el proceso de contratación de nuestros servicios.</li><br>
                <li>Datos financieros, mediante transferencia electrónica.</li><br>
                <li>RFC para completar los datos de facturación y realizar el proceso</li><br>
              </ul>
            </blockquote>
            <blockquote>
              <p>A nuestros proveedores solicitamos los siguientes datos personales: </p>
              <ul>
                  
                <li>Nombre, teléfono, correo electrónico, domicilio, fecha y lugar de nacimiento.</li><br>
                
              
              </ul>
            </blockquote>
            <blockquote>
              <p>A nuestros trabajadores solicitamos los siguientes datos personales: </p>
              <ul>
                  
                <li class="text-justify">Nombre, teléfono, correo electrónico, domicilio, fecha y lugar de nacimiento.</li><br>
                <li>Antecedentes laborales, puesto y motivo de terminación laboral.</li><br>
              
              </ul>
            </blockquote>
          <h4 class="text-justify">Los datos particulares y personales que nos proporcionan son utilizados estrictamente en la realización de funciones propias de nuestra empresa y por ningún motivo serán transferidos a terceros</h4>
           
          </div><!-- /.blog-post -->
    
         
    
     
        </div><!-- /.blog-main -->
    
        <aside class="col-md-4 blog-sidebar">
          
          <div class="p-5">
        
            <img class="shadow-lg p-3 mb-5 bg-white rounded" src="assets/imagenes/Rslogo.png" width="100%" height="100%" alt="...">
          </div>
        </aside>
       
        
  
       
        
    </div>
    