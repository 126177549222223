import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonio',
  templateUrl: './testimonio.component.html',
  styleUrls: ['./testimonio.component.css']
})
export class TestimonioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
